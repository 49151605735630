<template>
  <v-app>
    <v-main>
      <v-fade-transition leave-absolute>
        <router-view />
      </v-fade-transition>
    </v-main>

    <!-- <Footer /> -->

    <v-snackbar
      v-model="messageSnackbar.showing"
      :color="messageSnackbar.color"
      :timeout="3000"
    >
      <div class="d-flex justify-space-between align-center">
        <span class="font-weight-medium">
          {{ messageSnackbar.message }}
        </span>
        <v-btn text @click="messageSnackbar.showing = false"> ok </v-btn>
      </div>
    </v-snackbar>

    <ConfirmDialog ref="confirmDialog" />
    
  </v-app>
</template>

<script>
import Vue from "vue";
import Footer from "@/layouts/default/Footer.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
const assets = require.context('./assets/');


export default {
  name: "App",
  components: {
    Footer, ConfirmDialog,
  },
  metaInfo: {
    title: "Tesys",
    htmlAttrs: { lang: "pt-br" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  data: () => ({
    menu: false,
    messageSnackbar: {
      showing: false,
      message: "",
      color: "",
    },
  }),
  methods: {
    showMessage(color, msg) {
      this.messageSnackbar.showing = false;
      this.$nextTick(() => {
        this.messageSnackbar.color = color;
        this.messageSnackbar.message = msg;
        this.messageSnackbar.showing = true;
      });
    },
    confirmDialog(title, subtitle, deleteFunction){
      this.$refs.confirmDialog.open(title, subtitle, deleteFunction);
    },
  },
  created() {
    Vue.prototype.$showMessage = this.showMessage;
    Vue.prototype.$confirmDialog = this.confirmDialog;
    Vue.prototype.$assets = assets;
  },
};
</script>

<style>
  body, html {
    overflow: auto;
  }
  .v-application,
  .v-application [class*="text-"] {
    font-family: "Montserrat" !important;
  }
</style>
