<template>
  <div class="fill-height d-flex flex-column" v-resize="getContentHeight">
    <v-sheet
      v-if="!isWelcome"
      color="primary"
      class="rounded-tr-sm d-flex justify-start elevation-3 pa-0 ma-0"
      style="z-index: 1; overflow: hidden;"
      :height="$vuetify.breakpoint.smAndUp ? '56px' : '40px'"
      :min-height="$vuetify.breakpoint.smAndUp ? '56px' : '40px'"
    >
      <template v-if="availableCompanies.length != 0 && loading == false">
        <v-autocomplete v-if="$vuetify.breakpoint.smAndUp"
          :value="currentCompany"
          @input="changeCompany"
          prepend-inner-icon="mdi-domain"
          :loading="loading"
          x-large filled solo hide-details
          background-color="primary darken-1" dark
          color="primary"
          class="primary d-inline-block rounded-0"
          style="max-width: 300px; min-width: 300px;"
          item-color="primary"
          item-text="name"
          item-value="id"
          label="Empresa"
          :items="availableCompanies"
          no-data-text="Nenhuma empresa encontrada"
        />
        <v-img
          v-else
          max-width="100px"
          contain
          src="/tesys-white.png"
        />
        <div class="fill-height d-flex align-center">
          <v-icon class="ml-4 mr-3" color="white">
            mdi-chevron-right-circle-outline
          </v-icon>
        </div>
      </template>
      <v-slide-x-transition>
        <v-tabs
          v-show="showingSubmenu"
          active-class="white primary--text rounded-t-sm "
          class="primary white--text pt-1 ml-1"
          slider-color="primary"
          dark
          height="100%"
          background-color="primary"
        >
          <v-tab
            draggable="false"
            class="mr-1"
            v-for="(route, i) in currentSubmenu"
            :key="i"
            :to="{name: route.name}" exact
          >
            {{ route.meta.title }}
          </v-tab>
        </v-tabs>
      </v-slide-x-transition>
    </v-sheet>
    <!--v Menu Mobile v-->
    <div v-if="!$vuetify.breakpoint.smAndUp" width="100%" class="primary d-flex align-center">
      <v-autocomplete 
        :value="currentCompany"
        @input="changeCompany"
        auto-select-first
        prepend-inner-icon="mdi-domain"
        :loading="loading"
        filled solo hide-details
        dense
        background-color="primary darken-1" dark
        color="primary"
        class="primary d-inline-block rounded-0"
        style="max-width: 300px; min-width: 300px;"
        item-color="primary"
        item-text="name"
        item-value="id"
        label="Empresa"
        :items="availableCompanies"
        no-data-text="Nenhuma empresa encontrada"
      />
      <v-spacer/>
      <v-app-bar-nav-icon color="white" class="mr-2" @click="$emit('openMenu')"/>
    </div>
    <div
      ref="contentHeight"
      class="flex-grow-1 rounded-0 rounded-br-sm"
      style="overflow-y: auto; overflow-x: hidden;"
    >
      <v-slide-y-reverse-transition leave-absolute>
        <router-view
          ref="content"
          @tabname="contentTabname = $event"
          @companiesUpdated="loadCompanies()"
          :contentHeight="contentHeight"
        />
      </v-slide-y-reverse-transition>
    </div>
  </div>
</template>

<script>

export default {
  name: "Content",
  data: (() => ({
    loading: false,
    availableCompanies: [],
    companies: {
      all: [],
      accountant: [],
      direct_access: [],
    },
    submenus: {},
    showingSubmenu: false,
    contentTabname: undefined,
    contentHeight: 0,
    currentCompany: undefined,
  })),

  computed: {
    currentMenuRoute(){
      return this.$route.matched[1];
    },
    currentSubmenu(){
      const id = this.currentMenuRoute.meta.id;
      let submenu = this.submenus[id].children;
      submenu = submenu.filter((r) => r.redirect == undefined);
      if (!this.$store.state.user.is_accountant){
        submenu = submenu.filter((sm) => !sm.accountantRequired)
      }
      if (id == 'cash-flows'){
        submenu = submenu.slice(0, submenu.length-1);
        if (this.$route.name == 'cash-flow'){
          let route = this.$route;
          if (route.params.name){
            route.meta.title = route.params.name;
          } else if (this.contentTabname !== undefined){
            route.meta.title = this.contentTabname;
          }
          submenu.push(route);
        }
      }
      return submenu || [];
    },
    isWelcome() {
      if (this.$route.name == "app") return true || false;
    },
  },

  methods: {
    loadSubmenus(){
      this.submenus = {};
      this.$router.options.routes
        .filter((r) => (r.meta || {}).id == 'app')[0].children
        .filter((r) => (r.meta || {}).id != undefined)
        .forEach((route) => {
          this.submenus[route.meta.id] = route;
        });    
    },
    loadCompanies() {
      this.loading = true;
      this.$axios
        .get("/companies/available")
        .then((response) => {
          this.companies = response.data;
          this.updateAvailableCompanies(true);
        })
        .catch((error) => {
          console.log(error);
          this.$showMessage("error", "Erro inesperado ao carregar as Empresas");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reloadContent(){
      if (this.$refs.content.reload){
        this.$nextTick(() => {
          this.$refs.content.reload();
        });
      }
    },

    async changeCompany(company_id, sleepBeforeReload){
      const previousCompany = this.currentCompany;
      this.currentCompany = company_id;
      this.loading = true;
      try {
        const response = await this.$axios.patch(`/change-company/${company_id}`);
        this.$store.commit("setUser", response.data);
      } catch (error){
        console.log(error);
        if (error.response){
          if (error.response.status == 401){
            this.$showMessage('warning', "Troca de empresa não autorizada");
            return;
          }
        }
        this.$showMessage('error', "Erro ao trocar de emrpesa");
        this.currentCompany = previousCompany;
      } finally {
        this.loading = false;
        if (sleepBeforeReload){
          await new Promise(resolve => setTimeout(resolve, 200));
        }
        this.reloadContent();
      }
    },
    async setToAvailableCompany(){
      const current = this.availableCompanies
                          .filter(c => c.id == this.currentCompany)[0];
      if (current == undefined){
        await this.changeCompany(this.availableCompanies[0].id, true);
        return true;
      }
    },
    async updateAvailableCompanies(updateCompaniesList){
      let reloaded = false;
      if (updateCompaniesList){
        switch (this.currentMenuRoute.meta.companies){
          case 'all':
            this.availableCompanies = this.companies.all;
            break;
          case 'accountant':
            this.availableCompanies = this.companies.accountant;
            reloaded = await this.setToAvailableCompany();
            break;
          case 'direct_access':
            this.availableCompanies = this.companies.direct_access;
            reloaded = await this.setToAvailableCompany();
            break;
          default:
            this.availableCompanies = [];
        }
      }
      if (!reloaded){
        this.reloadContent();
      }
    },
    getContentHeight(){
      setTimeout(() => {
        if (this.$refs.contentHeight != undefined){
          this.contentHeight = this.$refs.contentHeight.clientHeight;
        }
      }, 500);
    },
  },

  watch: {
    currentMenuRoute(current, previous){
      current = current.meta.companies;
      previous = previous.meta.companies;
      this.updateAvailableCompanies(
        current != previous
      );
    },
  },

  beforeRouteLeave(to, from, next) {
    this.showingSubmenu = false;
    setTimeout(() => {
      next();
      this.showingSubmenu = true;
    }, 100);
  },

  created(){
    this.loadSubmenus();
    this.loadCompanies();
  },

  mounted() {
    this.showingSubmenu = true;
    if (this.$store.state.user){
      this.currentCompany = this.$store.state.user.current_company.id;
    }
  },
}
</script>
