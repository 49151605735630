import Vue from 'vue'
import VueRouter from 'vue-router'
import Content from '@/views/app/Content.vue';
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/entrar',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/cadastro',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/esqueceu-a-senha',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/recuperar-senha/:code',
    name: 'recover-password',
    component: () => import('@/views/RecoveryPassword.vue')
  },
  {
    path: '/solicitacao/:code',
    name: 'answer-request',
    component: () => import('@/views/app/RequestResponse.vue'),
  },
  {
    path: '/formulario/:code',
    name: 'form-response',
    component: () => import('@/views/app/FormResponse.vue'),
  },
  {
    path: '/app/',
    component: () => import('@/views/app/Main.vue'),
    meta: { id: 'app' },
    children: [
      {
        path: '',
        name: 'app',
        component: () => import('@/views/app/WelcomePage.vue')
      },
      {
        path: 'configuracoes/',
        component: Content,
        meta: { id: 'profile', companies: 'direct_access', },
        children: [
          {
            path: 'perfil',
            name: 'profile',
            meta: { title: 'Perfil' },
            component: () => import('@/views/app/Profile.vue')
          },
          ...(process.env.NODE_ENV === "production" ? []:[
            {
              accountantRequired: true,
              path: 'assinaturas',
              name: 'subscriptions',
              meta: { title: 'Assinaturas' },
              component: () => import('@/views/app/Subscriptions.vue')
            },
          ]),
        ],
      },
      {
        path: 'caixa',
        component: Content,
        meta: { id: 'cash-flows', companies: 'all', },
        children: [
          {
            path: '',
            name: "cash-flows",
            meta: { title: 'Controles de Caixa' },
            component: () => import('@/views/app/CashFlows.vue')
          },
          {
            path: ':cash_flow_id',
            name: 'cash-flow',
            meta: { title: 'Controle Atual' },
            component: () => import('@/views/app/CashFlow.vue')
          },
        ],
      },
      {
        path: 'docs/',
        component: Content,
        meta: { id: 'documents', companies: 'direct_access', },
        children: [
          {
            path: '',
            name: 'documents',
            meta: { title: 'Documentos' },
            component: () => import('@/views/app/Documents.vue'),
          },
        ],
      },
      {
        path: 'formularios/',
        component: Content,
        meta: { id: 'forms', companies: 'direct_access', },
        children: [
          {
            path: '',
            name: 'forms',
            meta: { title: 'Formulários' },
            component: () => import('@/views/app/FormRequests.vue'),
          },
          {
            path: 'recorrentes',
            name: 'recurrences',
            meta: { title: 'Recorrentes' },
            component: () => import('@/views/app/FormRecurrences.vue'),
          },
        ],
      },
      {
        path: 'malha-fina/',
        component: Content,
        meta: { id: 'malha-fina', companies: 'direct_access', },
        children: [
          {
            path: '',
            name: 'malha-fina',
            meta: { title: 'Malha Fina' },
            redirect: {name: 'processing-status'},
            // component: () => import('@/views/app/MalhaFina.vue'),
          },
          {
            path: 'processamento',
            name: 'processing-status',
            meta: { title: 'Processamento' },
            component: () => import('@/views/app/ProcessingStatus.vue'),
          },
        ],
      },
      {
        path: 'clientes/',
        component: Content,
        meta: { id: ['companies'] },
        children: [
          {
            accountantRequired: true,
            path: 'empresas',
            name: 'companies',
            meta: { title: 'Empresas' },
            component: () => import('@/views/app/Companies.vue'),
          },    
        ],
      },
      {
        path: 'usuarios/',
        component: Content,
        meta: { id: ['user-management'] },
        children: [ 
          {
            path: 'usuarios',
            name: 'user-management',
            meta: { title: 'Usuários' },
            component: () => import('@/views/app/UserManagement.vue'),
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
