import Vue from 'vue';
import Vuex from 'vuex';
import { getCookie, removeCookie } from 'tiny-cookie';

Vue.use(Vuex)

function getUserData(){
  let token = getCookie('token');
  if (token){
    return JSON.parse(atob(token.split('.')[1]));
  }
}

const user = getUserData();
const logged = (user !== undefined);

export default new Vuex.Store({
  state: {
    logged,
    user,
  },
  mutations: {
    setUser(state, user){
      state.user = user;
      state.logged = true;
    },
    updateUser(state){
      state.user = getUserData();
      state.logged = (state.user !== undefined);
    },
    logout(state){
      state.user = {};
      state.logged = false;
      if (process.env.NODE_ENV === "production"){
        removeCookie('token', {domain: '.tesys.com.br'});
      } else {
        removeCookie('token');
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
