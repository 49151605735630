import Vue from 'vue';
import App from './App.vue';
import { sync } from 'vuex-router-sync';
import ScrollLoader from 'vue-scroll-loader'
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import router from './router.js';
import store from './store';
import rules from '@/rules';
import utils from '@/utils';
import money from 'v-money';

Vue.use(VueTheMask);
Vue.use(money);
Vue.use(ScrollLoader)
Vue.config.productionTip = false;

var axios = require('axios').create({
  baseURL: process.env.NODE_ENV === "production" ?
    "https://api.tesys.com.br" :
    "http://localhost:8000",
	withCredentials: true,
});

axios.interceptors.response.use(
  (response) => {
    store.commit('updateUser');
    return response;
  },
  (error) => {
    if (!error || !error.response){
      return Promise.reject(error);
    }
    let status = error.response.status;
    if (status == 403){
      store.commit('logout');
      router.replace({name: 'login'});
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
Vue.prototype.$rules = rules;
Vue.prototype.$utils = utils;
sync(store, router);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
