<template>
  <v-dialog v-model="showing" width="600px" persistent>
    <v-card>
      <v-toolbar flat dark color="primary" max-height="35px"/>
      <v-card-title class="px-4 pt-3">
        {{ title }}
      </v-card-title>
      <v-card-subtitle class="px-4 py-4">
        {{ subtitle }}
      </v-card-subtitle>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn text @click="showing = false" :disabled="loading">
          Cancelar
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          :loading="loading"
          @click="executeDelete()"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",

  data: () => ({
    showing: false,
    loading: false,
    title: undefined,
    subtitle: undefined,
    deleteFunction: undefined,
  }),
  
  methods: {
    open(title, subtitle, deleteFunction) {
      this.title = title;
      this.subtitle = subtitle;
      this.deleteFunction = deleteFunction;
      this.showing = true;
    },
    executeDelete() {
      this.loading = true;
      Promise.resolve(this.deleteFunction())
        .then(() => {
          this.showing = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
