const tinycolor = require("tinycolor2");

export default {
    moneyFormat: {
        decimal: ',',
        thousands: '.',
        precision: 2,
    },
    capitalize(text) {
      if (text) return text.charAt(0).toUpperCase() + text.slice(1);
    },
    textColorForBackground(color){
        return tinycolor(color).getBrightness() > 128 ? 'black':'white';
    },
    keyPressOnlyNumbers(evt){
        evt = evt || window.event;
        let c = evt.which || evt.keyCode;
        if (48 <= c && c <= 57) {
            return true;
        }
        evt.preventDefault();
    },
    formatMoney(value){
        if (!value){
            return value;
        }
        let [num, decimal] = value.toFixed(2).split('.');
        let negative = false;
        if (num[0] == '-'){
            num = num.slice(1);
            negative = true;
        }
        let newNum = "";
        while (num.length > 3){
            newNum = '.' + num.slice(-3) + newNum;
            num = num.slice(0, -3);
        }
        return `${negative ? '-':''}${num}${newNum},${decimal}`;
    },
    formattedMoneyToDecimal(str){
        if (!str){
            return str;
        }
        return str.replaceAll(/\./g, '').replace(',', '.');
    },
    formatDate(date){
        if (!date) return undefined;
        if (typeof date != Date){
            if (date.length == '2022-00-00'.length){
                date += " 00:00:00";
            }
            date = new Date(date);
        }
        return date.toLocaleDateString('pt-BR');
    },
    formatTime(time){
        if (typeof time != Date){
            time = new Date(time);
        }
        return time.toLocaleTimeString('pt-BR').split(':').slice(0, 2).join(':');
    },
    formatDateTime(date){
        if (typeof date != Date){
            date = new Date(date);
        }
        return this.formatDate(date) + " " + this.formatTime(date);
    },
    fromBrDatetime(str){
        if (!str){
            return str;
        }
        let date = "", time = "";
        let parts = str.split(' ');
        if (parts.length >= 1){
            date = parts[0];
        }
        if (parts.length >= 2){
            time = parts.slice(1).join(' ');
            if (time){
                time = " " + time;
            }
        }
        if (date){
            date = date.split('/').reverse().join('-');
            if (date.length < '0000-00-00'.length){
                return undefined;
            }
        }
        return date + time;
    },
    sendToClipboard(txt){
        navigator.clipboard.writeText(txt);
    },
};
