function validateCPF(cpf){
  cpf = cpf.replace(/[^\d]/g, '')
  cpf = cpf.split('').map(n => parseInt(n))
  let digits = [9, 10]
  let equals = 0
  for (let i = 0; i < cpf.length-1; ++i){
    if (cpf[i] == cpf[i+1]){
      equals++
    }
  }
  if (equals == cpf.length-1){
    return false
  }
  for (let d in digits){
    let digit = digits[d]
    let sum = 0, rev
    for (let i = 0; i < digit; ++i){
      sum += cpf[i] * (digit+1 - i)
    }
    rev = sum % 11
    if (rev < 2){
      rev = 0
    } else {
      rev = 11 - rev
    }
    if (rev != cpf[digit]){
      return false
    }
  }
  return true
}

function validateCNPJ(cnpj){
  cnpj = cnpj.replace(/[^\d]/g, '')
  cnpj = cnpj.split('').map(n => parseInt(n))
  let digits = [12, 13]
  for (let d in digits){
    let digit = digits[d]
    let sum = 0
    for (let i = 0, acc = digit-7; i < digit; ++i, --acc){
      sum += cnpj[i] * acc
      if (acc == 2) acc = 10
    }
    if (sum == 0) return false
    let rev = sum % 11
    if (rev < 2){
      rev = 0
    } else {
      rev = 11 - rev
    }
    if (rev != cnpj[digit]){
      return false
    }
  }
  return true
}

export default {
  required: val => !!val || "Campo obrigatório.",

  requiredList: val => !val || val.length > 0 || "Campo obrigatório.",

  equalPassword: getPassword => val =>
    val == getPassword() || "As senhas não estão iguais.",

  passwordMin: val =>
    (val != undefined && val.length >= 8) ||
    "A senha deve conter pelo menos 8 dígitos.",

  cep: val =>
    (val != undefined && val.length == 9) ||
    "O CEP deve conter 9 números.",

  cpf: val => {
    if (!val) return true
    if ((val || '').length < 14)
      return "Estão faltando números do CPF."
    if (!validateCPF(val))
      return "Número do CPF inválido."
    return true
  },

  cnpj: val => {
    if (!val) return true
    if ((val || '').length < 18)
      return "Estão faltando números do CNPJ."
    if (!validateCNPJ(val))
      return "Número do CNPJ inválido."
    return true
  },

  phone: val => !val || ([14,15].includes((val || '').length) || "Estão faltando números do telefone."),
  
  email: val =>
    (!val || /^([a-zA-Z][a-zA-Z0-9_.-]*@([a-zA-Z][a-zA-Z0-9_-]*)(\.[a-zA-Z][a-zA-Z0-9_-]*[a-zA-Z])+)?$/g.test(val))
    || "Insira um e-mail válido.",

  date: val =>
    !val ||
    (/^([0-9]{2}\/){2}[0-9]{4}$/g.test(val) &&
      (parseInt(val.split("/")[0]) <= 31 &&
        parseInt(val.split("/")[1]) <= 12 &&
        parseInt(val.split("/")[2]) > 999)) ||
    "Insira uma data válida.",
}
